var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("v-card", { staticClass: "pa-5" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between align-center pb-3" },
          [
            _c("div", { staticClass: "headline font-weight-bold" }, [
              _vm._v(" " + _vm._s(_vm.selectedOrg.name) + " "),
            ]),
            _c(
              "div",
              { staticClass: "text-xs-right" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-modal")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("fas fa-times")])],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm.fetchingOrganizationUsers
          ? _c(
              "div",
              { staticClass: "text-center pa-3" },
              [
                _c("v-progress-circular", {
                  staticClass: "ma-12",
                  attrs: {
                    width: "3",
                    size: "50",
                    color: "green",
                    indeterminate: "",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("v-divider"),
                _c(
                  "div",
                  { staticClass: "pb-5" },
                  [
                    _c("v-switch", {
                      attrs: {
                        label: "CC client contacts in data request emails",
                        color: "success",
                        value: "success",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.requestMailOrgCc,
                        callback: function ($$v) {
                          _vm.requestMailOrgCc = $$v
                        },
                        expression: "requestMailOrgCc",
                      },
                    }),
                    _vm.requestMailOrgCc
                      ? _c(
                          "v-expansion-panels",
                          {
                            staticClass: "pb-3 pt-5",
                            attrs: { accordion: "" },
                            model: {
                              value: _vm.expansionPanel,
                              callback: function ($$v) {
                                _vm.expansionPanel = $$v
                              },
                              expression: "expansionPanel",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  { staticClass: "font-weight-bold pa-2 pl-5" },
                                  [
                                    _vm._v(
                                      " Users to be CC'd (" +
                                        _vm._s(
                                          _vm.ccEnabledUsers.length +
                                            _vm.usersAdded.length
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Add more users to be CC'd",
                                        clearable: "",
                                        outlined: "",
                                        items: _vm.ccDisabledUsers,
                                        "item-text": "user.email",
                                        "item-value": "id",
                                        multiple: "",
                                        readonly: _vm.savingChanges,
                                        "hide-details": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function ({ item, index }) {
                                              return [
                                                index < 1
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        staticClass:
                                                          "caption font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.user.email
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                index === 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "(+" +
                                                            _vm._s(
                                                              _vm.usersAdded
                                                                .length - 1
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1721799934
                                      ),
                                      model: {
                                        value: _vm.usersAdded,
                                        callback: function ($$v) {
                                          _vm.usersAdded = $$v
                                        },
                                        expression: "usersAdded",
                                      },
                                    }),
                                    _vm.ccEnabledUsers.length
                                      ? _c("v-data-table", {
                                          staticClass: "pt-5",
                                          attrs: {
                                            "items-per-page": 5,
                                            headers: _vm.userTableHeaders,
                                            items: _vm.ccEnabledUsers,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: `item.removeUser`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          icon: "",
                                                          readonly:
                                                            _vm.savingChanges,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " fas fa-user-times "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("v-divider"),
                _c(
                  "div",
                  { staticClass: "pa-5 pb-0 text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "success",
                          loading: _vm.savingChanges,
                          disabled: _vm.disableSaveButton,
                          depressed: "",
                        },
                        on: { click: _vm.saveChanges },
                      },
                      [_vm._v(" Save Changes ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }