import { render, staticRenderFns } from "./OrganizationPreferencesModal.vue?vue&type=template&id=72728c3e&"
import script from "./OrganizationPreferencesModal.vue?vue&type=script&lang=ts&"
export * from "./OrganizationPreferencesModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/clearglass/turbokat/clearadmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72728c3e')) {
      api.createRecord('72728c3e', component.options)
    } else {
      api.reload('72728c3e', component.options)
    }
    module.hot.accept("./OrganizationPreferencesModal.vue?vue&type=template&id=72728c3e&", function () {
      api.rerender('72728c3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/client/components/OrganizationPreferencesModal.vue"
export default component.exports