
import { Component, Vue } from 'vue-property-decorator';
import AppGrid from '@/components/grid/AppGrid.vue';
import { RowOptions, GridOptions } from 'tui-grid';
import DashboardSubNavbar from '../../dashboard/components/DashboardSubNavbar.vue';
import DashboardSubNavbarPagination from '@/modules/dashboard/components/DashboardSubNavbarPagination.vue';
import DashboardSubNavbarFilter from '@/modules/dashboard/components/DashboardSubNavbarFilter.vue';
import DashboardSubNavbarSort from '@/modules/dashboard/components/DashboardSubNavbarSort.vue';
import DashboardSubNavbarCustomize from '@/modules/dashboard/components/DashboardSubNavbarCustomize.vue';
import * as at from '../store/actionTypes';
import { PaginatedParams, PaginationMeta } from '@/types';
import {
  FilterBuilder,
  FilterOperatorSlugs,
  FilterPlan,
} from '@/components/filter/types';
import {
  CustomizerBuilder,
  CustomizerPlan,
  CustomizerColumnWithOpt,
  ColumnDefinition,
} from '@/components/customizer/types';
import { Filter } from '@/components/filter/Filter';
import { Sort } from '@/components/sort/Sort';
import { Customizer, CustomizerType } from '@/components/customizer/Customizer';
import { SortBuilder, SortPlan } from '@/components/sort/types';
import { Permissions, userHasPermission } from '@/utils/Permissions';
import { CustomGridRenderer } from '@/components/grid/CustomGridRenderer';
import Organisation from '@/models/Organisation';
import OrganizationPreferencesModal from '../components/OrganizationPreferencesModal.vue';
import OrganizationUtils from '@/utils/OrganizationUtils';
import OrganizationActionsSelector from '@/modules/dashboard/components/OrganizationActionsSelector.vue';
import QueryUtils, { QueryType } from '@/utils/QueryUtils';
import { DISPLAY_SNACKBAR } from '@/modules/dashboard/store/actionTypes';
import {
  ClientContractModalSlug,
  PreferredCurrencyModalSlug,
} from '../../dashboard/types';
import { GridSortPlanPersister } from '@/components/grid/types';
import { QueryGridSortPlanPerister } from '@/components/grid/QueryGridSortPlanPersister';
import { cloneDeep } from 'lodash';
import InvestmentUtils from '@/utils/InvestmentUtils';

@Component({
  components: {
    OrganizationActionsSelector,
    AppGrid,
    DashboardSubNavbar,
    DashboardSubNavbarSort,
    DashboardSubNavbarFilter,
    DashboardSubNavbarCustomize,
    DashboardSubNavbarPagination,
    OrganizationPreferencesModal,
  },
})
export default class ListClientsPage extends Vue {
  public gridRenderId = Date.now();
  public selectedOrg: Organisation | null = null;
  public sortSchema: SortBuilder | null = null;
  public filterSchema: FilterBuilder | null = null;
  public customizerSchema: CustomizerBuilder | null = null;
  public defaultCustomizerPlan: CustomizerPlan | null = null;
  public columns: CustomizerColumnWithOpt[] | null = null;
  public isPreferencesModalOpen = false;
  public snackBar = false;
  public snackbarMsg = '';
  public snackbarColor = 'success';
  public selectedClients: Array<RowOptions> = [];
  public gridSortPersister!: GridSortPlanPersister;

  public $refs!: {
    ag: AppGrid;
  };

  created() {
    this.initColumns();
    this.initFilterSchema();
    this.initSortSchema();
    this.initCustomizer();
    this.initGridSortPersister();
    this.fetchData();
  }

  public fetchData() {
    const query = this.$route?.query ?? {};

    let page = undefined;
    if (query[QueryType.PAGE]) {
      page = `${query[QueryType.PAGE]}`;
    }

    if (Object.keys(query)?.length > 0) {
      this.filterSchema = Filter.rePlan(
        this.filterSchema,
        Filter.resolvePlanFromQuery(query, this.filterSchema)
      );
      this.sortSchema = Sort.rePlan(
        this.sortSchema,
        Sort.resolvePlanFromQuery(query)
      );
    }

    this.customizerSchema = Customizer.resolvePlanFromQuery(
      query,
      this.customizerType,
      this.customizerSchema
    );
    this.reOrderColumns();

    this.fetchClients({ page });
  }

  public get customizerType(): string {
    return CustomizerType.CLIENTS;
  }

  public get isReady(): boolean {
    return !this.isLoading && this.columns !== null;
  }

  public get isLoading(): boolean {
    return this.$store.getters.fetchingClients;
  }

  public get clients(): Organisation[] {
    return this.$store.getters.clients?.data ?? [];
  }

  public get pagination(): PaginationMeta {
    return this.$store.getters.clients?.meta ?? {};
  }

  public get gridOptions(): Partial<GridOptions> {
    return {
      columnOptions: {
        frozenCount: this.customizerSchema?.frozenColumns ?? 0,
      },
    };
  }

  public get mappedClientRows() {
    return this.clients?.map((client: Organisation) => {
      return {
        ...client,
        autoRollOverFunds:
          OrganizationUtils.mapAutoRollOverFundsDetails(client),
      };
    });
  }

  private get getSchemeUpdateActionItems(): string[] {
    return [ClientContractModalSlug, PreferredCurrencyModalSlug];
  }

  public get rows(): RowOptions[] {
    return this.mappedClientRows;
  }

  public get filterCount(): number {
    return this.filterSchema?.filterSets?.length ?? 0;
  }

  public get sortCount(): number {
    return this.sortSchema?.sortSets?.length ?? 0;
  }

  public get filterPlan(): FilterPlan | null {
    if (!this.filterSchema) {
      return null;
    }

    const { schema, ...filterPlan } = this.filterSchema;
    return filterPlan;
  }

  public get sortPlan(): SortPlan | null {
    if (!this.sortSchema) {
      return null;
    }

    const { schema, ...sortPlan } = this.sortSchema;
    return sortPlan;
  }

  public initColumns() {
    this.columns = Customizer.getInitialColumns(
      this.customizerType,
      this.defaultColumns()
    );
  }

  public defaultColumns(): CustomizerColumnWithOpt[] {
    return this.columnDefinitions().map((c, i) => ({
      ...c,
      position: i,
      slug: c.name,
      label: c?.header ?? c.name,
      sortable: c?.sortable ?? false,
      ellipsis: c?.sortable ?? false,
      width: c?.width,
      comparator: InvestmentUtils.compareValues,
    }));
  }

  public columnDefinitions(): ColumnDefinition[] {
    const columns: ColumnDefinition[] = [
      {
        name: 'id',
        header: 'ID',
        ellipsis: true,
      },
      {
        name: 'name',
        header: 'Name',
        sortable: true,
        ellipsis: true,
      },
      {
        name: 'childOrganizationsCount',
        header: 'Schemes Provided',
        sortable: true,
        ellipsis: true,
      },
      {
        name: 'preferredCurrency',
        header: 'Preferred Currency',
        ellipsis: true,
      },
      {
        name: 'contractDuration',
        header: 'Contract Duration',
        ellipsis: true,
      },
      {
        name: 'autoRollOverFunds',
        header: 'Auto Roll Over Funds Enabled',
        renderer: {
          type: CustomGridRenderer.make({
            name: 'button',
            parent: this,
            props: {
              outlined: true,
              customStyle: 'cursor:default',
            },
          }),
        },
      },
    ];

    if (userHasPermission(Permissions.manageORgPreferences)) {
      columns.push({
        name: 'manage-preferences',
        header: 'Manage Preferences',
        renderer: {
          type: CustomGridRenderer.make({
            name: 'button',
            parent: this,
            props: {
              items: [
                {
                  actionType: 'edit-users-email-notifications',
                  color: 'blue lighten-1',
                  iconName: 'fas fa-cog',
                  title: 'Add/Edit user email notifications',
                },
              ],
              icon: true,
              tooltip: true,
            },
            listeners: {
              click: ({
                rowKey,
                actionType,
              }: {
                rowKey: number;
                actionType: string;
              }) => {
                const row = this.getGrid()?.invoke('getRow', rowKey) ?? null;
                if (row?.id) {
                  let orgRow = row as unknown as Organisation;
                  this.selectedOrg = orgRow;
                  this.isPreferencesModalOpen = true;
                }
              },
            },
          }),
        },
      });
    }

    return columns;
  }

  public initFilterSchema() {
    this.filterSchema = Filter.make({
      conjunction: 'AND',
      filterSets: [],
      schema: {
        availableAttributes: [
          {
            name: 'ID',
            slug: 'id',
            type: 'text',
          },
          {
            name: 'Name',
            slug: 'name',
            type: 'text',
          },
          {
            name: 'Created At',
            slug: 'created_at',
            type: 'date',
          },
          {
            name: 'Preferred Currency',
            slug: 'preferred_currency',
            type: 'list',
            options: {
              availableValues: this.$store.getters?.currencyList ?? [],
              itemText: 'displayName',
              itemValue: 'slug',
              multiple: true,
              availableOperators: [FilterOperatorSlugs.IS_ANY_OF],
              includeSetAsArray: true,
              default_color_bg: 'info lighten-4',
              default_color_fg: 'info',
            },
          },
        ],
      },
    });
  }

  public initSortSchema() {
    this.sortSchema = Sort.make({
      sortSets: [],
      schema: {
        availableAttributes: [
          {
            name: 'Name',
            slug: 'name',
            type: 'text',
          },
          {
            name: 'Created At',
            slug: 'created_at',
            type: 'date',
          },
          {
            name: 'Schemes Provided',
            slug: 'childOrganizationsCount',
            type: 'text',
          },
        ],
      },
    });
  }

  public initCustomizer() {
    this.customizerSchema = Customizer.make({
      schema: {},
      frozenColumns: 0,
      availableColumns: this.columns ?? [],
    });
    this.defaultCustomizerPlan = cloneDeep({
      ...this.customizerSchema,
      availableColumns: this.defaultColumns(),
    });
  }

  private initGridSortPersister() {
    this.gridSortPersister = new QueryGridSortPlanPerister(this.$router);
  }

  public async fetchClients(args?: PaginatedParams<{}>) {
    const sortPlan = this.sortPlan;
    const filterPlan = this.filterPlan;
    const page = args?.page ?? this.pagination.current_page;
    const limit =
      args?.limit ??
      this.customizerSchema?.itemsPerPage ??
      this.pagination.per_page;
    const params = { filterPlan, sortPlan, page, limit };

    await this.$store.dispatch(at.FETCH_CLIENTS, params).catch((e) =>
      this.$store.dispatch(DISPLAY_SNACKBAR, {
        color: 'error',
        text: e.message,
      })
    );
  }

  public filtersApplied({ plan }: { plan: FilterPlan }) {
    this.filterSchema = Filter.rePlan(this.filterSchema, plan);
    this.appendQueryParamsToUrl(QueryType.FILTER, Filter.toQuery(plan, true));
  }

  public sortsApplied({ plan }: { plan: SortPlan }) {
    this.sortSchema = Sort.rePlan(this.sortSchema, plan);
    this.appendQueryParamsToUrl(QueryType.SORT, Sort.toRouteQuery(plan));
  }

  public appendQueryParamsToUrl(
    queryType: QueryType,
    queryParamsToUpdate: Record<string, any>
  ) {
    this.$router.push({
      hash: `${Date.now()}`,
      query: QueryUtils.resolveQueryParams(
        queryType,
        queryParamsToUpdate,
        this.$route.query
      ),
    });
  }

  public customizationApplied({ plan }: { plan: CustomizerPlan }) {
    this.customizerSchema = Customizer.rePlan(this.customizerSchema, plan);
    this.columns = plan.availableColumns;
    this.reOrderColumns();

    if (+(this.pagination?.per_page ?? 0) !== +plan.itemsPerPage) {
      this.appendQueryParamsToUrl(QueryType.LIMIT, {
        limit: this.customizerSchema?.itemsPerPage,
      });
      return;
    }

    // Fetching data re-renders the grid
    // Otherwise, we do it forcefully.
    this.reRenderGrid();
  }

  public onEditingStart(e: Event) {
    // console.log({ e });
  }

  public pageChange(page: number) {
    this.appendQueryParamsToUrl(QueryType.PAGE, { page });
  }

  public getCheckedRows() {
    const rows = (this.getGrid()?.invoke('getCheckedRows') ?? []) as Array<
      Record<string, any>
    >;
    this.selectedClients = rows.map((r) => r.id);
  }

  private reOrderColumns() {
    if (!this.customizerSchema || !this.columns) {
      return;
    }

    this.columns = Customizer.reOrderColumns(
      this.customizerSchema,
      this.columns ?? [],
      'position'
    );
  }

  private reRenderGrid() {
    this.gridRenderId = Date.now();
  }

  private getGrid() {
    return (this.$refs.ag as any)?.getGrid();
  }

  private closeOrgPreferencesModal(result?: { success: boolean; msg: string }) {
    this.isPreferencesModalOpen = false;

    if (result) {
      this.snackBar = true;
      this.snackbarMsg = result.msg ?? 'Details saved successfully';
      this.snackbarColor = result.success ? 'success' : 'error';
    }
  }
}
