var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "page list-clients-page overflow-hidden" },
    [
      _c("DashboardSubNavbar", {
        attrs: { title: "Clients (Providers)" },
        scopedSlots: _vm._u([
          {
            key: "leftSide",
            fn: function () {
              return [
                _c("OrganizationActionsSelector", {
                  attrs: {
                    clients: _vm.selectedClients,
                    "enabled-actions": _vm.getSchemeUpdateActionItems,
                    "organization-type": "provider",
                  },
                  on: { refresh: _vm.fetchClients },
                }),
                _c("DashboardSubNavbarFilter", {
                  staticClass: "mr-2",
                  attrs: {
                    loading: _vm.isLoading,
                    schema: _vm.filterSchema,
                    "filter-count": _vm.filterCount,
                  },
                  on: { apply: _vm.filtersApplied },
                }),
                _c("DashboardSubNavbarSort", {
                  attrs: {
                    loading: _vm.isLoading,
                    schema: _vm.sortSchema,
                    "sort-count": _vm.sortCount,
                  },
                  on: { apply: _vm.sortsApplied },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "rightSide",
            fn: function () {
              return [
                _c("DashboardSubNavbarCustomize", {
                  attrs: {
                    schema: _vm.customizerSchema,
                    "default-plan": _vm.defaultCustomizerPlan,
                    loading: _vm.isLoading,
                    type: _vm.customizerType,
                  },
                  on: { apply: _vm.customizationApplied },
                }),
                _c(
                  "div",
                  { staticClass: "dashboard-subnavbar__item" },
                  [
                    _c("DashboardSubNavbarPagination", {
                      attrs: {
                        disabled: !_vm.isReady,
                        pagination: _vm.pagination,
                      },
                      on: { pageChange: _vm.pageChange },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.isReady
        ? _c(
            "v-container",
            {
              staticClass: "d-flex align-center justify-center fill-height",
              attrs: { fluid: "" },
            },
            [
              !_vm.isReady
                ? _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "32", width: "3" },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isReady
        ? _c("div", { staticClass: "fill-height" }, [
            _c(
              "div",
              { staticClass: "page__wrapper" },
              [
                _vm.isReady
                  ? _c("AppGrid", {
                      key: _vm.gridRenderId,
                      ref: "ag",
                      attrs: {
                        rows: _vm.rows,
                        columns: _vm.columns,
                        options: _vm.gridOptions,
                        "sort-persister": _vm.gridSortPersister,
                      },
                      on: {
                        "editing-start": _vm.onEditingStart,
                        scrollX: _vm.onEditingStart,
                        scrollY: _vm.onEditingStart,
                        scrollXEnd: _vm.onEditingStart,
                        scrollYEnd: _vm.onEditingStart,
                        getCheckedRows: _vm.getCheckedRows,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isPreferencesModalOpen
        ? _c("OrganizationPreferencesModal", {
            attrs: { "selected-org": _vm.selectedOrg },
            on: { "close-modal": _vm.closeOrgPreferencesModal },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          staticClass: "text-center",
          attrs: { top: "", timeout: 3000, color: _vm.snackbarColor },
          model: {
            value: _vm.snackBar,
            callback: function ($$v) {
              _vm.snackBar = $$v
            },
            expression: "snackBar",
          },
        },
        [
          _c(
            "div",
            { staticClass: "justify-center", staticStyle: { width: "100%" } },
            [_vm._v(" " + _vm._s(_vm.snackbarMsg) + " ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }