var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "client-module page" }, [
    !_vm.moduleIsReady
      ? _c(
          "div",
          {
            staticClass:
              "d-flex fill-height align-center justify-center overflow-hidden",
          },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: "32", width: "2" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.moduleIsReady
      ? _c("div", { staticClass: "fill-height" }, [_c("router-view")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }