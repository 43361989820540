
import { Component, Vue } from 'vue-property-decorator';
import { User } from '../../models/User';

@Component({
  components: {},
})
export default class ClientModule extends Vue {
  public moduleIsReady = false;

  created() {
    this.getAllDetails();
  }

  public get currentUser(): User {
    return this.$store.getters.currentUser;
  }

  public getAllDetails() {
    const actions: Promise<any>[] = [];

    Promise.all(actions)
      .then((values) => {
        this.moduleIsReady = true;
        return values;
      })
      .catch((err) => {
        this.moduleIsReady = false;
        if (err && err.response && err.response.status == 500) {
          console.log({ err });
        }
      });
  }
}
