
import Organisation from '@/models/Organisation';
import OrganizationUser from '@/models/OrganizationUser';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FETCH_ORG_USERS, SAVE_ORG_PREFERENCES } from '../store/actionTypes';
import { SaveOrgPreferencesRequest } from '../types';

@Component({})
export default class OrganizationPreferencesModal extends Vue {
  @Prop({ required: true }) selectedOrg!: Organisation;

  public dialog = true;
  public fetchingOrganizationUsers = false;
  public requestMailOrgCc: string | null = null;
  public ccEnabledUsers: OrganizationUser[] = [];
  public ccDisabledUsers: OrganizationUser[] = [];
  public savingChanges = false;
  public usersAdded: OrganizationUser[] = [];
  public usersDeleted: OrganizationUser[] = [];
  public expansionPanel = 0;

  mounted() {
    this.requestMailOrgCc = this.selectedOrg.meta?.mark_cc_in_request_mails
      ? 'success'
      : null;
    this.fetchOrgUsers();
  }

  public get userTableHeaders() {
    return [
      {
        text: 'User',
        value: 'user.email',
      },
      {
        text: 'Remove',
        value: 'removeUser',
        align: 'right',
        sortable: false,
      },
    ];
  }

  public get disableSaveButton() {
    return (
      (this.selectedOrg.meta?.mark_cc_in_request_mails ? 'success' : null) ===
        this.requestMailOrgCc &&
      !this.usersAdded.length &&
      !this.usersDeleted.length
    );
  }

  async fetchOrgUsers() {
    this.fetchingOrganizationUsers = true;
    try {
      const data = await this.$store.dispatch(
        FETCH_ORG_USERS,
        this.selectedOrg.id
      );
      this.filterUsers(data);
    } catch {
      this.$emit('close-modal', false);
    } finally {
      this.fetchingOrganizationUsers = false;
    }
  }

  filterUsers(orgUsers: OrganizationUser[]) {
    (orgUsers ?? []).forEach((orgUser) => {
      if (orgUser.meta?.mark_cc_in_request_mails) {
        this.ccEnabledUsers.push(orgUser);
      } else {
        this.ccDisabledUsers.push(orgUser);
      }
    });

    this.requestMailOrgCc = orgUsers[0]?.organization?.meta
      ?.mark_cc_in_request_mails
      ? 'success'
      : null;
    if (this.selectedOrg.meta) {
      this.selectedOrg.meta.mark_cc_in_request_mails =
        orgUsers[0]?.organization?.meta?.mark_cc_in_request_mails;
    }
    this.expansionPanel =
      this.requestMailOrgCc && this.ccEnabledUsers.length ? 1 : 0;
  }

  async saveChanges() {
    this.savingChanges = true;
    const requestBody: SaveOrgPreferencesRequest = {
      id: this.selectedOrg.id,
      markCc: this.requestMailOrgCc === 'success' ? true : false,
      usersToAdd: this.usersAdded,
      usersToDelete: this.usersDeleted,
    };

    try {
      const data = await this.$store.dispatch(
        SAVE_ORG_PREFERENCES,
        requestBody
      );
      this.$emit('close-modal', {
        success: true,
        msg: 'Preferences saved successfully',
      });
    } catch (e) {
      this.$emit('close-modal', {
        success: false,
        msg: e.response.data.msg ?? 'Something went wrong',
      });
    } finally {
      this.savingChanges = false;
    }
  }

  deleteItem(item: any) {
    this.usersDeleted.push(item.id);
    this.ccDisabledUsers.push(item);
    this.ccEnabledUsers = this.ccEnabledUsers.filter((u) => u.id !== item.id);
  }
}
