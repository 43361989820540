import { render, staticRenderFns } from "./ListClientsPage.vue?vue&type=template&id=e56ee92a&"
import script from "./ListClientsPage.vue?vue&type=script&lang=ts&"
export * from "./ListClientsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/clearglass/turbokat/clearadmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e56ee92a')) {
      api.createRecord('e56ee92a', component.options)
    } else {
      api.reload('e56ee92a', component.options)
    }
    module.hot.accept("./ListClientsPage.vue?vue&type=template&id=e56ee92a&", function () {
      api.rerender('e56ee92a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/client/pages/ListClientsPage.vue"
export default component.exports